// src/components/Footer.js
import React from "react";
import CompanyLogo from "../assets/Genoday-light-logo.svg";
// import Link from "react-router-dom"
import Twitter from "../assets/twitter-footer.svg";
import Facebook from "../assets/fb-footer.svg";
import Instagram from "../assets/insta-footer.svg";
import LinkedIN from "../assets/linkedin-footer.svg";
import "../styles/Footer.css";


function Footer() {
  return (
    <div className="footer-container-contactus">
    <div className="footer-upper-section">
      <div className="subscribe">
        <h2>Subscribe to our newsletter</h2>
        <div className="input-group">
          <input type="email" placeholder="Email Address" />
          <button>Subscribe</button>
        </div>
      </div>
    </div>

    <div className="footer-lower-section">
      <div className="comapnyinfo-socialicons">
        <div className="company-info">
          <div className="logo">
            <img src={CompanyLogo} alt="company-logo" />
            <span>Genoday</span>
          </div>
          <p>
            Connecting every dot from product design to app development is
            our claim to fame. We’re your digital partner for catering
            bespoke industry equipped solutions to strictly meet your
            business requirements.
          </p>
        </div>
        <div className="social-icons">
          <img src={Twitter} alt="twitter-logo" />
          <img src={Facebook} alt="facebook-logo" />
          <img src={Instagram} alt="instagram-logo" />
          <img src={LinkedIN} alt="linkedin-logo" />
        </div>
      </div>
      <div className="quick-links">
        <h3>Quick Links</h3>
        <div className="links">
          <div>About</div>
          <div>Services</div>
          <div>Industries</div>
          <div>Resources</div>
          <div>Careers</div>
          <div>Contact</div>
        </div>
      </div>
      <div className="services">
        <h3>Services</h3>
        <div className="service-links">
          <div>Skeleton Design</div>
          <div>Frontend Development</div>
          <div>Backend Development</div>
          <div>Cloud Services</div>
        </div>
      </div>
      <div className="contact-details">
        <h3>Contact Us</h3>
        <div className="info">
          <div className="info-heading-footer">Email Address</div>
          <div className="info-heading-content">connect@genoday.com</div>
          <div className="info-heading-footer">Location</div>
          <div className="info-heading-content">
            201, Ram Krishna Nivas Towers, Sanjeeva Reddy Nagar,
            Hyderabad, Telangana - 500038
          </div>
        </div>
      </div>
    </div>
    <div className="legal">
      <div className="left-content">
        Copyright 2023. All Rights Reserved.
      </div>
      <div className="right-content">
        <span className="right-content-left-span">
          Terms & Conditions
        </span>
        <span className="right-content-right-span">Privacy Policy</span>
      </div>
    </div>
  </div>
  );
}

export default Footer;

/* Add appropriate CSS styles for the footer components */
