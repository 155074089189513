// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
// import Services from "./components/Services";
import Industries from "./Pages/Industries/Industries";
import Resources from "./Pages/Resources/Resources";
import Careers from "./Pages/Career/career";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar"
import JobDetails from "./Pages/JobDetails/JobDetails";
import Services from "./Pages/Services/Services";
import './styles/App.css'

function App() {
  return (
    <Router>
      <div>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<AboutUs/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/industries" element={<Industries/>} />
          <Route path="/resources" element={<Resources/>} />
          <Route path="/careers" element={<Careers/>} />
          <Route path="/job/:id" element={<JobDetails/>} />
          <Route path="/contact" element={<ContactUs/> } />/
          {/* <Route path="/services" element={<Services/>} />/ */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
