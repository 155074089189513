import React from "react";
import LineImage from "../../assets/aboutus-line.svg";
import Tick from "../../assets/tick-about-us.svg";
import TickCircle from "../../assets/Tick-circle-about-us.svg";
import ImageOne from "../../assets/about-us-page-1.png";
import ImageSecond from "../../assets/aboutus-page-2.png";
import ImageThird from "../../assets/aboutus-page-3.png";
import ImageFourth from "../../assets/aboutus-page-4.png";
import Innovation from "../../assets/Idea.svg";
import Integrity from "../../assets/Technology.svg";
import Excellence from "../../assets/Excellence.svg";
import Collaboration from "../../assets/Deal.svg";
import OurTeamOne from "../../assets/aboutus-ourteams-firstimage.png";
import OurTeamTwo from "../../assets/aboutus-ourteams-secondimage.png";
import OurTeamThree from "../../assets/aboutus-ourteams-thirdimage.png";
import OurTeamFour from "../../assets/aboutus-ourteams-fourthimage.png";
import OurTeamFive from "../../assets/aboutus-ourteams-fifthimage.png";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="careers-container-aboutus">
      <div className="aboutus-main">About US</div>
      <div className="upper-section-aboutus">
        <div className="line-image">
          <img src={LineImage} alt="line" />
        </div>
        <div className="testimonials-title-aboutus">Our Mission</div>
        <div className="testimonials-heading-aboutus">
          Our mission: Driving Digital Transformation
        </div>
      </div>
      <div className="second-outer-container">
      <div className="left-container-aboutus">
        <div className="heading-aboutus">
          To drive digital transformation for businesses across various
          industries, providing tailored solutions that enhance efficiency,
          productivity, and growth.
        </div>
        <div className="container-aboutus">
          <div className="item-aboutus">
            <img
              src={TickCircle}
              alt="tick"
              className="tick-circle-image-aboutus"
            />
            <img src={Tick} alt="tick" className="tick-image-aboutus" />
            <div className="text-content-aboutus">
              <div className="subheading-aboutus">Innovation:</div>
              <div className="content-aboutus">
                Leveraging the latest technologies to create sustainable and
                scalable solutions.
              </div>
            </div>
          </div>
          <div className="item-aboutus">
            <img
              src={TickCircle}
              alt="tick"
              className="tick-circle-image-aboutus"
            />
            <img src={Tick} alt="tick" className="tick-image-aboutus" />
            <div className="text-content-aboutus">
              <div className="subheading-aboutus">Integrity:</div>
              <div className="content-aboutus">
                Maintaining the highest standards of ethics and transparency.
              </div>
            </div>
          </div>
          <div className="item-aboutus">
            <img
              src={TickCircle}
              alt="tick"
              className="tick-circle-image-aboutus"
            />
            <img src={Tick} alt="tick" className="tick-image-aboutus" />
            <div className="text-content-aboutus">
              <div className="subheading-aboutus">Excellence:</div>
              <div className="content-aboutus">
                Delivering high-quality solutions that meet evolving client
                needs.
              </div>
            </div>
          </div>
          <div className="item-aboutus">
            <img
              src={TickCircle}
              alt="tick"
              className="tick-circle-image-aboutus"
            />
            <img src={Tick} alt="tick" className="tick-image-aboutus" />
            <div className="text-content-aboutus">
              <div className="subheading-aboutus">Partnership:</div>
              <div className="content-aboutus">
                Building long-term relationships with clients to help them
                navigate the digital landscape and achieve their strategic
                objectives.
              </div>
            </div>
          </div>
        </div>
      </div>
      
        <div className="right-container-aboutus">
          <div className="left-side-image-aboutus">
            <div>
              {" "}
              <img src={ImageOne} alt="line" className="image-one-aboutus" />
            </div>
            <div>
              {" "}
              <img src={ImageSecond} alt="line" className="image-two-aboutus" />
            </div>
          </div>
          <div className="right-side-image-aboutus">
            <div>
              {" "}
              <img src={ImageThird} alt="line" className="image-three-aboutus" />
            </div>
            <div>
              {" "}
              <img src={ImageFourth} alt="line" className="image-four-aboutus" />
            </div>
          </div>
        </div>
      </div>
      <div className="second-container-aboutus">
        <div className="sub-second-container-aboutus">
          <div className="inner-divone">
            <div className="count">150+</div>
            <div className="text-aboutus">Successful Projects</div>
          </div>
          <div className="inner-divtwo">
            <div className="count">100+</div>
            <div className="text-aboutus">Satisfied clients worldwide</div>
          </div>
          <div className="inner-divthree">
            <div className="count">10+</div>
            <div className="text-aboutus">Years of industry experience</div>
          </div>
          <div className="inner-divfour">
            <div className="text-aboutus">
              Serving clients in <div className="count">20+</div> countries
            </div>
          </div>
        </div>
      </div>
      <div className="aboutus-container">
        <div className="line-image-aboutus">
          <img src={LineImage} alt="line" />
        </div>
        <div className="aboutus-content-div">
          <div className="aboutus-title">Core Values</div>
          <div className="aboutus-heading-sub">
            Our Core Values: Guiding Principles for Success
          </div>
        </div>
      </div>
      <div className="aboutus-success-container-core">
        <div className="aboutus-success-item-one">
          <div className="aboutus-success-count">
            {" "}
            <img src={Innovation} alt="Innovation-pic" />
          </div>
          <div className="aboutus-success-subheading">Innovation</div>
          <div className="aboutus-success-text">
            Pushing the boundaries of technology. Embracing creativity and
            forward-thinking for practical solutions.
          </div>
        </div>
        <div className="aboutus-success-item-two">
          <div className="aboutus-success-count">
            <img src={Integrity} alt="Integrity-pic" />
          </div>
          <div className="aboutus-success-subheading">Integrity</div>
          <div className="aboutus-success-text">
            Maintaining transparency, honesty, and ethical practices. Acting
            with integrity to keep our promises.
          </div>
        </div>
        <div className="aboutus-success-item-one">
          <div className="aboutus-success-count">
            <img src={Excellence} alt="Excellence-pic" />
          </div>
          <div className="aboutus-success-subheading">Excellence</div>
          <div className="aboutus-success-text">
            Striving for excellence in everything we do. Delivering
            high-quality, robust solutions.
          </div>
        </div>
        <div className="aboutus-success-item-two">
          <div className="aboutus-success-count">
            <img src={Collaboration} alt="Collaboration-pic" />
          </div>
          <div className="aboutus-success-subheading">Collaboration</div>
          <div className="aboutus-success-text">
            Working closely with clients for better results. Fostering a
            supportive and innovative team environment.
          </div>
        </div>
      </div>
      <div className="aboutus-our-teams-main-section">
        <div className="aboutus-our-teams-left-section">
          <div className="line-image">
            <img src={LineImage} alt="line" />
          </div>
          <div className="aboutus-our-teams-left-section-div">
            <div className="aboutus-our-teams-title">our teams</div>
            <div className="aboutus-our-teams-heading">
              Meet Our Teams: The Heart of Genoday Technologies
            </div>
            <div className="aboutus-our-teams-text">
              At Genoday Technologies, our team is our greatest asset. Comprising
              talented professionals from various backgrounds, our team brings a
              wealth of experience and expertise to every project. We foster a
              collaborative environment where ideas are shared, and creativity
              thrives, ensuring we deliver the best solutions to our clients.
            </div>
          </div>
        </div>
        <div className="aboutus-our-teams-right-section">
          <div className="aboutus-our-teams-main-picture">
            <div className="aboutus-our-teams-picture-left">
              <div className="aboutus-our-teams-picture-one">
                <div className="our-team-pics">
                  <img src={OurTeamOne} alt="OurTeamOne" />
                </div>
                <div className="aboutus-our-teams-name-one">
                  Alex Johnson
                </div>
                <div className="aboutus-our-teams-designation-one">quality assurance specialist</div>
              </div>
              <div className="aboutus-our-teams-picture-two">
                <div className="our-team-pics">
                  <img src={OurTeamTwo} alt="OurTeamTwo" />
                </div>
                <div className="aboutus-our-teams-name-two">
                  Samantha Lee
                </div>
                <div className="aboutus-our-teams-designation-two">quality assurance specialist</div>
              </div>
            </div>

            <div className="aboutus-our-teams-picture-center">
              <div className="aboutus-our-teams-picture-three">
                <div className="our-team-pics">
                  <img src={OurTeamThree} alt="OurTeamThree" />
                </div>
                <div className="aboutus-our-teams-name-three">Michael Kim</div>
                <div className="aboutus-our-teams-designation-three">quality assurance specialist</div>
              </div>
            </div>

            <div className="aboutus-our-teams-picture-right">
              <div className="aboutus-our-teams-picture-four">
                <div className="our-team-pics">
                  <img src={OurTeamFour} alt="OurTeamFour" />
                </div>
                <div className="aboutus-our-teams-name-four">Michael Kim</div>
                <div className="aboutus-our-teams-designation-four">quality assurance specialist</div>
              </div>
              <div className="aboutus-our-teams-picture-five">
                <div className="our-team-pics">
                  <img src={OurTeamFive} alt="OurTeamFive" />
                </div>
                <div className="aboutus-our-teams-name-five">Michael Kim</div>
                <div className="aboutus-our-teams-designation-five">quality assurance specialist</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
