import React from "react";
import CareerLineImage from "../../assets/industriesline.svg";
import LineImage from "../../assets/aboutus-line.svg";
import CenterImage from "../../assets/services_center_image1.png";
import Wireframe from "../../assets/Services/Wireframe.svg";
import CloudService from "../../assets/Services/Cloud service.svg";
import Coding from "../../assets/Services/Coding.svg";
import WebDesign from "../../assets/Services/Web design.svg";
import FirstImageService from "../../assets/Services/Services-last-sec-first.svg"
import SecondImageService from "../../assets/Services/Services-last-sec-second.svg"
import ThirdImageService from "../../assets/Services/Services-last-sec-third.svg"
import "./Services.css";

function Services() {
  return (
    <div className="service-container">
       <div className="services-main">Services</div>
      <div className="service-line-image">
        <img src={CareerLineImage} alt="line" />
      </div>
      <div className="service-content-div">
        <div className="service-title-second-heading">Our Services</div>
        <div className="service-heading-sub">
          Comprehensive Solutions for Your Digital Needs
        </div>
        <div className="service-layout">
          <div className="service-left">
            <div className="service-item">
              <div className="service-item-upper">
                <div className="service-item-image">
                  <img src={Wireframe} alt="Wireframe" />
                </div>
                <div className="service-item-text">Skeleton Design</div>
              </div>
              <div className="service-item-lower">
                Our Skeleton Design service focuses on creating the foundational
                blueprint for your project. This includes wireframes,
                prototypes, and architectural designs that outline the structure
                and flow of your application, ensuring a clear and efficient
                development process.
              </div>
            </div>
            <div className="service-item">
              <div className="service-item-upper">
                <div className="service-item-image">
                  <img src={Coding} alt="coding" />
                </div>
                <div className="service-item-text">Backend Development</div>
              </div>
              <div className="service-item-lower">
                Our Backend Development service ensures your application has a
                solid and scalable foundation. We build secure, efficient, and
                scalable backend systems that handle your data and business
                logic, ensuring your application runs smoothly and reliably.
              </div>
            </div>
          </div>
          <div className="service-center-page">
            <img src={CenterImage} alt="center" />
          </div>
          <div className="service-right">
            <div className="service-item">
              <div className="service-item-upper">
                <div className="service-item-image">
                  <img src={WebDesign} alt="webdesign" />
                </div>
                <div className="service-item-text">Frontend Development</div>
              </div>
              <div className="service-item-lower">
                We specialize in creating dynamic and responsive user interfaces
                that provide an engaging user experience. Our frontend
                development team uses the latest technologies and frameworks to
                build interfaces that are not only visually appealing but also
                intuitive and user-friendly.
              </div>
            </div>
            <div className="service-item">
              <div className="service-item-upper">
                <div className="service-item-image">
                  <img src={CloudService} alt="Cloudservice" />
                </div>
                <div className="service-item-text">Cloud Services</div>
              </div>
              <div className="service-item-lower">
                We offer comprehensive Cloud Services that enable seamless
                integration and deployment of your applications on cloud
                platforms. Our services include cloud migration, infrastructure
                setup, and management, ensuring your applications are scalable,
                secure, and cost-effective.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="upper-section-service">
        <div className="line-image">
          <img src={LineImage} alt="line" />
        </div>
        <div className="testimonials-title-service">Technologies Used</div>
        <div className="testimonials-heading-service">
          Driving Innovation with Cutting-Edge Tools
        </div>
      </div>
      <div className="last-section-service">
        <div className="first-last-section-div">
          <div className="left-last-section-service">
            <div className="number-left-last-section-service">01</div>
            <div className="text-left-last-section-service">
            <div className="heading-text-left-last-section-service">Frontend Stack Technologies</div>
            <div className="sub-heading-text-left-last-section-service">Modern frontend development relies on HTML, CSS, and JavaScript for creating the structure, style, and interactivity of web applications. Frameworks like React, Vue.js, and Angular streamline UI development, while preprocessors such as Sass enhance CSS capabilities. Build tools like Webpack automate tasks, and version control with Git ensures efficient collaboration. Integrating APIs enables dynamic data interaction, ensuring responsive and engaging user experiences.</div>
            </div>
          </div>
          <div className="right-last-section-service"><img src={FirstImageService} alt="firstimageservice" /></div>
        </div>

        <div className="second-last-section-div">
        <div className="right-last-section-service"><img src={SecondImageService} alt="secondimageservice" /></div>
          <div className="left-last-section-service">
            <div className="number-left-last-section-service">02</div>
            <div className="text-left-last-section-service">
            <div className="heading-text-left-last-section-service">Backend Stack Technologies</div>
            <div className="sub-heading-text-left-last-section-service">Backend development utilizes programming languages like Python, Java, or Node.js for server-side logic and data processing. Frameworks such as Django (Python), Spring (Java), or Express (Node.js) provide robust architecture and tools for building scalable applications. Databases like PostgreSQL, MySQL, or MongoDB manage data storage, while RESTful APIs facilitate communication between frontend and backend systems. Deployment tools like Docker and continuous integration (CI/CD) pipelines ensure reliable application deployment and maintenance. These technologies form the backbone of backend development, enabling secure, efficient, and scalable web applications.</div>
            </div>
          </div>
        </div>

        <div className="third-last-section-div">
          <div className="left-last-section-service">
            <div className="number-left-last-section-service">03</div>
            <div className="text-left-last-section-service">
            <div className="heading-text-left-last-section-service">Mobile Stack Technologies</div>
            <div className="sub-heading-text-left-last-section-service">Mobile development leverages languages like Swift for iOS and Kotlin for Android to build native applications. Frameworks such as React Native and Flutter enable cross-platform development, allowing a single codebase for both iOS and Android. Backend services like Firebase provide real-time databases and authentication, while tools like Xcode and Android Studio offer comprehensive development environments. APIs and SDKs extend app functionality, and CI/CD pipelines ensure smooth app deployment and updates. These technologies power the creation of high-performance, user-friendly mobile applications.</div>
            </div>
          </div>
          <div className="right-last-section-service"><img src={ThirdImageService} alt="thirdimageservice" /></div>
        </div>
      </div>
    </div>
  );
}

export default Services;
