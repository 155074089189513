import React from "react";
import CareerLineImage from "../../assets/industriesline.svg";
import HealthCare from "../../assets/Resources/Group.png"
import BlogOne from "../../assets/Industries/first-card-industries.png";
import FirstProfilePic from "../../assets/Industries/profile-pic-first-industries.png";
import SecondCard from "../../assets/Resources/industries-card-2.png"
import ThirdCard from "../../assets/Resources/industries-card-3.png"
import FourthCard from "../../assets/Resources/industries-card-4.png"
import FifthCard from "../../assets/Resources/industries-card-5.png"
import SixthCard from "../../assets/Resources/industries-card-6.png"
import SeventhCard from "../../assets/Resources/industries-card-7.png"
import EightCard from "../../assets/Resources/industries-card-8.png"
import NinethCard from "../../assets/Resources/industries-card-9.png"
import TenthCard from "../../assets/Resources/industries-card-10.png"
import EleventhCard from "../../assets/Resources/industries-card-11.png"
import TwelthCard from "../../assets/Resources/industries-card-12.png"
import FinanceIcon from "../../assets/Resources/mdi_finance.svg"
import RetailIcon from "../../assets/Resources/fluent_building-retail-toolbox-20-regular.svg"
import BloackChainIcon from "../../assets/Resources/eos-icons_blockchain.svg"
import "./Industries.css";

function Industries() {
  return (
    <div>
      <div className="industries">Industries</div>
      <div className="industries-line-image">
        <img src={CareerLineImage} alt="line" />
      </div>
      <div className="industries-content-div">
        <div className="industries-title-second-heading">INDUSTRIES</div>
        <div className="industries-heading-sub">
          Driving Innovation Across Industries
        </div>
      </div>
      <div className="main-section-div-industries">
        <div className="section-div-industries">
          <div className="left-sec-div-industries">
            <div className="number-left-div-industries">01</div>
            <div className="box-left-div-industries-one">
              <div className='left-part-one'><img src={HealthCare} alt='HealthcareImage' /></div>
              <div className="right-part-one">
                <div className="upper-right-div-industries-one">Healthcare</div>
                <div className="lower-right-div-industries-one">
                  Empowering Healthcare with Technology
                </div>
              </div>
            </div>
          </div>
          <div className="right-sec-div-industries">
          <div className="heading-right-sec-div-industries">case studies</div>
            <div className="card-right-sec-div-industries">
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={BlogOne} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={SecondCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                      Emily Davis
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                  AI for Personalized Medicine
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                  Customizing treatments with AI analysis. Revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={ThirdCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                      Daniel Wilson
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                  AI in Healthcare: Trends
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="left-section-div-industries">
          <div className="right-sec-div-industries">
          <div className="heading-right-sec-div-industries">case studies</div>
            <div className="card-right-sec-div-industries">
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={FourthCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={FifthCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={SixthCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="left-sec-div-industries-right">
            <div className="number-left-div-industries">02</div>
            <div className="box-left-div-industries-two">
              <div className='left-part-two'><img src={FinanceIcon} alt='FinanceImage' /></div>
              <div className="right-part-two">
                <div className="upper-right-div-industries-two">Finance</div>
                <div className="lower-right-div-industries-two">
                Innovative Solutions for Financial Services
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-div-industries">
          <div className="left-sec-div-industries">
            <div className="number-left-div-industries">03</div>
            <div className="box-left-div-industries-two">
              <div className='left-part-three'><img src={RetailIcon} alt='RetailIconImage' /></div>
              <div className="right-part-three">
                <div className="upper-right-div-industries-three">Retail</div>
                <div className="lower-right-div-industries-three">
                Transforming Retail Experiences
                </div>
              </div>
            </div>
          </div>
          <div className="right-sec-div-industries">
          <div className="heading-right-sec-div-industries">case studies</div>
            <div className="card-right-sec-div-industries">
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={SeventhCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={EightCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={NinethCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="left-section-div-industries">
          <div className="right-sec-div-industries">
          <div className="heading-right-sec-div-industries">case studies</div>
            <div className="card-right-sec-div-industries">
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={TenthCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={EleventhCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
              <div className="industries-featured-card">
                <div className="upper-sec-industries-featured-card">
                  <img src={TwelthCard} alt="firstimage" />
                </div>
                <div className="lower-sec-industries-featured-card">
                  <div className="first-line-lower-sec-industries-featured-card">
                    <div className="profile-pic-first-line-lower-sec-industries-featured-card">
                      <img src={FirstProfilePic} alt="profilepicture" />
                    </div>
                    <div className="userinfo-first-line-lower-sec-industries-featured-card">
                      <span className="name-first-line-lower-sec-industries-featured-card">
                        Jane Doe
                      </span>
                    </div>
                  </div>
                  <div className="heading-first-line-lower-sec-industries-featured-card">
                    The future Of AI In Healthcare
                  </div>
                  <div className="sub-heading-first-line-lower-sec-industries-featured-card">
                    Discover how artificial intelligence is revolutionizing
                    healthcare delivery and patient outcomes.
                  </div>
                  <div className="date-first-line-lower-sec-industries-featured-card">
                    july 10, 2024
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="left-sec-div-industries-right">
            <div className="number-left-div-industries">04</div>
            <div className="box-left-div-industries-four">
              <div className='left-part-four'><img src={BloackChainIcon} alt='BlockchainImage' /></div>
              <div className="right-part-four">
                <div className="upper-right-div-industries-four">Blockchain</div>
                <div className="lower-right-div-industries-four">
                Revolutionizing Industries with Blockchain Technology
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Industries;
