// src/components/Navbar.js
import React from 'react';
// import { Link } from 'reactl-scrol';
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/genoday-logo.svg'

function Navbar() {
  return (
    <nav className="navbar">
      <div className='navbar-inner'>
        <div className="navbar-left">
                <img src={CompanyLogo} alt="Company Logo" className="navbar-logo" />
                <span className="navbar-company-name">Genoday</span>
            </div>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about" >About Us</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/industries">Industries</Link></li>
        <li><Link to="/resources">Resources</Link></li>
        <li><Link to="/careers">Careers</Link></li>
        <li><Link to="/contact">Contact Us</Link></li>
        {/* <li><Link to="/Footer" smooth={true} duration={500}>Footer</Link></li> */}
      </ul>
      </div>
      {/* <div className='navbar-bg'></div> */}
    </nav>
  );
}

export default Navbar;
