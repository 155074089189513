import React, { useState } from "react";
import { useParams } from "react-router-dom";
import "./JobDetails.css";

function JobDetails() {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="job-details">
      <h1>Job Details for Position ID: {id}</h1>

      <div className="job-overview">
        <h2>Overview</h2>
        <p>Genoday Technologies is seeking a skilled Software Engineer to join our dynamic team. As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications that drive our innovative solutions. You will collaborate with cross-functional teams to ensure the delivery of high-quality software that meets our clients' needs.</p>
      </div>

      <div className="job-responsibilities">
        <h2>Responsibilities</h2>
        <ul>
          <li><span>Design and Development:</span><span>Create and implement software solutions that meet project requirements and client expectations.</span></li>
          <li><span>Code Quality: </span><span>Write clean, maintainable, and efficient code following best practices and industry standards.</span></li>
          <li><span>Collaboration:</span><span> Work closely with product managers, designers, and other engineers to develop robust and scalable applications.</span></li>
          <li><span>Testing: </span><span>Conduct unit testing and debugging of applications to ensure optimal performance and reliability.</span></li>
          <li><span>Documentation: </span><span>Maintain comprehensive documentation of code, processes, and project specifications.</span></li>
          <li><span>Continuous Improvement: </span><span>Stay updated with emerging technologies and industry trends to enhance skills and improve the software development process.</span></li>
        </ul>
      </div>

      <div className="job-requirements">
        <h2>Requirements</h2>
        <ul>
          <li><span>Education: </span><span>Bachelor’s degree in Computer Science, Engineering, or related field.</span></li>
          <li><span>Experience: </span><span>Minimum of 3 years of experience in software development.</span></li>
          <li><span>Technical Skills: </span><span>Proficiency in Java, Python, and C++.</span></li>
          <li><span>Problem-Solving: </span><span>Strong analytical and problem-solving skills.</span></li>
          <li><span>Communication: </span><span>Excellent verbal and written communication skills.</span></li>
          <li><span>Team Player: </span><span>Ability to work effectively in a team-oriented environment.</span></li>
          <li><span>Agile Methodology: </span><span>Familiarity with agile development practices and tools.</span></li>
        </ul>
      </div>

      <div className="job-skills">
        <h2>Skills</h2>
        <ul>
          <li><span>Programming Languages: </span><span>Java, Python, C++</span></li>
          <li>Development Tools: <span></span><span>Git, JIRA, Docker</span></li>
          <li>Frameworks: <span></span><span>Spring, Django, React</span></li>
          <li>Databases: <span></span><span>SQL, NoSQL (e.g., MongoDB)</span></li>
          <li>Cloud Platforms: <span></span><span>AWS, Azure</span></li>
          <li>Soft Skills: <span></span><span>Strong organizational skills, attention to detail, and the ability to manage multiple tasks.</span></li>
        </ul>
      </div>

      <div className="apply-section">
        <button className="apply-button" onClick={closeModal}>Cancel</button>
      </div>
      <div className="apply-section">
        <button className="apply-button" onClick={openModal}>Apply</button>
      </div>

      {modalOpen && (
        <div id="modal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="modal-heading">Apply for the Job</div>
            <div className="modal-text">Please fill in your details below.</div>
            <div className="form-row">
              <input type="text" placeholder="Full Name" />
              <input type="email" placeholder="Email" />
            </div>
            <div className="form-row">
              <input type="tel" placeholder="Phone Number" />
              <input type="url" placeholder="Portfolio" />
            </div>
            <div className="form-row">
              <label>Cover Letter</label>
              <textarea placeholder="Write your cover letter here..."></textarea>
            </div>
            <div className="form-row">
              <label>Resume</label>
              <input type="file" accept=".pdf,.doc,.docx" />
            </div>
            <div className="form-row">
              <input type="checkbox" id="info-checkbox" />
              <label htmlFor="info-checkbox">I'd like to receive more information about the company. I understand and agree to the <span className="privacy-policy">Privacy Policy</span>.</label>
            </div>
            <div className="form-buttons">
              <button className="cancel-button" onClick={closeModal}>Cancel</button>
              <button className="apply-button">Apply</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default JobDetails;
