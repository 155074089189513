// src/components/Home.js
import React, { useState } from 'react';
import '../styles/Home.css';
import Bulb from '../assets/bulb.svg'; 
import SecondImage from "../assets/AboutUs-secondimage.png";
import FirstImage from "../assets/AboutUs-firstimage.png";
import AboutUSLine from "../assets/aboutus-line.svg";
import SkeletonPic from "../assets/skeleton-services.png"
import FrontendPic from "../assets/frontend-services.png"
import BackendPic from "../assets/backend-services.png"
import CloudPic from "../assets/cloud-services.png"
import LineImage from "../assets/industriesline.png"; 
import IndustryImage1 from "../assets/industry1.png";
import IndustryImage2 from "../assets/industry2.png";
import IndustryImage3 from "../assets/industry3.png";
import CompanyLogo1 from "../assets/default-logo-company-name.svg"; 
import CompanyLogo2 from "../assets/default-logo-company-name.svg"; 
import CompanyLogo3 from "../assets/default-logo-company-name.svg";
import CompanyLogo4 from "../assets/default-logo-company-name.svg";
import CompanyLogo5 from "../assets/default-logo-company-name.svg";
import CompanyLogo6 from "../assets/default-logo-company-name.svg";
import Comma from "../assets/testimonials-comma.svg"
import Client1 from "../assets/testimonials-default.jpeg"; 
import Client2 from "../assets/testimonials-default.jpeg"; 
import Client3 from "../assets/testimonials-default.jpeg"; 
import Client4 from "../assets/testimonials-default.jpeg";
import Client5 from "../assets/testimonials-default.jpeg";
import StarIcon from "../assets/testimonials-star.svg";
import LeftArrow from "../assets/left-arrow-testimonials.svg";
import RightArrow from "../assets/right-arrow-testimonials.svg";
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/genoday-logo.svg'


function Home() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isContactUsVisible, setContactUsVisible] = useState(false);

    const clients = [
      {
        img: Client1,
        name: 'John Doe',
        designation: 'CEO, Company 1',
        text: 'Using this platform has revolutionized the way I manage my projects. The intuitive interface and robust features have saved me countless hours of work!'
      },
      {
        img: Client2,
        name: 'Jane Smith',
        designation: 'CTO, Company 2',
        text: 'Using this platform has revolutionized the way I manage my projects. The intuitive interface and robust features have saved me countless hours of work!'
      },
      {
        img: Client3,
        name: 'Emily Johnson',
        designation: 'COO, Company 3',
        text: 'Using this platform has revolutionized the way I manage my projects. The intuitive interface and robust features have saved me countless hours of work!'
      },
      {
        img: Client4,
        name: 'Michael Brown',
        designation: 'CFO, Company 4',
        text: 'This platform is simply amazing! It has streamlined our processes and boosted our productivity significantly.'
      },
      {
        img: Client5,
        name: 'Sarah Davis',
        designation: 'CMO, Company 5',
        text: 'A game changer for our business. The features are easy to use and the support team is fantastic!'
      }
    ];
  
    const handleLeftClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? clients.length - 3 : prevIndex - 1));
    };
  
    const handleRightClick = () => {
      setCurrentIndex((prevIndex) => (prevIndex === clients.length - 3 ? 0 : prevIndex + 1));
    };

    const handleOpenContactUs = () => {
      setContactUsVisible(true);
    };
  
    const handleCloseContactUs = () => {
      setContactUsVisible(false);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
    };

  return (
    <div>
      <nav className="navbar-home">
        <div className="navbar-left-home">
                <img src={CompanyLogo} alt="Company Logo" className="navbar-logo-home" />
                <span className="navbar-company-name-home">Genoday</span>
            </div>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/about" >About Us</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/industries">Industries</Link></li>
        <li><Link to="/resources">Resources</Link></li>
        <li><Link to="/careers">Careers</Link></li>
        <li><Link to="/contact">Contact Us</Link></li>
        {/* <li><Link to="/Footer" smooth={true} duration={500}>Footer</Link></li> */}
      </ul>
    </nav>
      <div className="home-container">
            <div className="main-div">
                <div className="first-div">
                    <div className="icon-text">
                    <img src={Bulb} alt="FaBulb" className="icon" />
                        <div className="text">Transforming Visions into Reality</div>
                    </div>
                </div>
                <div className="second-div">
                    Transforming <span className="highlight">Ideas</span> into <span className="highlight">Digital Solutions</span>
                </div>
                <div className="third-div">
                    <p>Empowering businesses with cutting-edge technology and innovative solutions.</p>
                </div>
                <div className="fourth-div">
                    <div className="button-container">
                        <div className="hover-bg"></div>
                        <button className="learn-more">Learn More</button>
                    </div>
                </div>
            </div>
        </div>
         <div className="about-us-container">
         <div className="about-us-left">
           <div className="image-wrapper">
             <div className="third-image"></div>
             <img src={SecondImage} alt="Second" className="second-image" />
             <div className="first-image-box">
               <img src={FirstImage} alt="First" className="first-image" />
             </div>
             <img src={AboutUSLine} alt="line" className="aboutus-line" />
           </div>
           
         </div>
   
         <div className="about-us-right">
           <div className="about-us-content">
             <div className="text-section">
               <div className="who-are-we">WHO ARE WE</div>
               <div className="title">Get to Know Genoday Technologies</div>
               <div className="description">
                 Welcome to Genoday Technologies! We are a forward-thinking
                 technology company dedicated to transforming innovative ideas into
                 exceptional digital solutions. Our team of experts is committed to
                 delivering high-quality software development, IT consulting, and
                 digital transformation services to help businesses thrive in a
                 digital world.
               </div>
               <div className="mission-section">
                 <div className="mission">
                   <h3>Our Mission:</h3>
                   <p>
                     To empower businesses through advanced technology solutions
                     and creative strategies. We strive to drive growth, foster
                     innovation, and create lasting value for our clients and
                     partners.
                   </p>
                   <h3>What We Do:</h3>
                   <p>
                     We specialize in a wide range of services, including custom
                     software development, IT consulting, and digital
                     transformation solutions. Whether you’re looking to develop a
                     new product, streamline your IT processes, or explore new
                     digital opportunities, we have the skills and experience to
                     support you every step of the way.
                   </p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div className="services-container">
      <div className="intro-section">
        <div className="intro-text">
          <img src={AboutUSLine} alt="line" className="services-line" />
          <span className="intro-title">OUR SERVICES ARSENAL</span>
        </div>
        <div className="intro-heading">Empowering your digital transformation</div>
      </div>
      <div className="services-list">
        <div className="service-item-home">
          <div className="service-icon">
            <img src={SkeletonPic} alt="Service 1" />
          </div>
          <div className="service-title">Skeleton Design</div>
          <div className="service-info"><div class="service-info-inner"><span className='service-info-up'>Skeleton Design</span><div class="break-line"/><span className='service-info-down'>Our skeleton design service lays the foundation for your project with a well-structured, intuitive layout. We focus on creating a seamless user experience that guides users effortlessly through your application.</span></div></div>
        </div>
        <div className="service-item-home">
          <div className="service-icon">
            <img src={FrontendPic} alt="Service 2" />
          </div>
          <div className="service-title">Frontend Development</div>
          <div className="service-info"><div class="service-info-inner"><span className='service-info-up'>Frontend Development</span><div class="break-line"/><span className='service-info-down'>Our skeleton design service lays the foundation for your project with a well-structured, intuitive layout. We focus on creating a seamless user experience that guides users effortlessly through your application.</span></div></div>
        </div>
        <div className="service-item-home">
          <div className="service-icon">
            <img src={BackendPic} alt="Service 3" />
          </div>
          <div className="service-title">Backend Development</div>
          <div className="service-info"><div class="service-info-inner"><span className='service-info-up'>Backend Development</span><div class="break-line"/><span className='service-info-down'>Our skeleton design service lays the foundation for your project with a well-structured, intuitive layout. We focus on creating a seamless user experience that guides users effortlessly through your application.</span></div></div>
        </div>
        <div className="service-item-home">
          <div className="service-icon">
            <img src={CloudPic} alt="Service 4" />
          </div>
          <div className="service-title">Cloud Services</div>
          <div className="service-info"><div class="service-info-inner"><span className='service-info-up'>Cloud Services</span><div class="break-line"/><span className='service-info-down'>Our skeleton design service lays the foundation for your project with a well-structured, intuitive layout. We focus on creating a seamless user experience that guides users effortlessly through your application.</span></div></div>
        </div>
      </div>
      <div className="servicesbutton-container">
        <div className="serviceshover-bg"></div>
        <button className="serviceslearn-more">Discover Our Services</button>
      </div>
    </div>
    <div className="industries-container">
      <div className="line-image-industries">
        <img src={LineImage} alt="line" />
      </div>
      <div className='content-div'>
      <div className="industries-title">
        Industries We Serve
      </div>
      <div className="industries-heading-sub">
        Tailored Solutions for Diverse Industries
      </div>
      <div className="industry-item">
        <div className="industry-left">
          <img src={IndustryImage1} alt="Industry 1" />
        </div>
        <div className="industry-right">
          <div className="industry-number">01.</div>
          <div className="industry-content">
            <div className="industry-heading">Healthcare</div>
            <div className="industry-description">
              <div className='industry-description-heading'>Description:</div><div className='industry-description-content'> We provide cutting-edge solutions to healthcare providers, enhancing patient care, streamlining operations, and ensuring data security.</div>
              <div className='industry-description-heading'>Case Study:</div><div className='industry-description-content'> Implemented an electronic health record (EHR) system for a major hospital, resulting in a 30% reduction in administrative workload and improved patient care coordination.</div>
              <div className="case-study-link"><a href="/case-study/healthcare">Read Full Case Study &gt;&gt;</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="industry-item">
        <div className="industry-right">
          <div className="industry-number">02.</div>
          <div className="industry-content">
            <div className="industry-heading">Finance</div>
            <div className="industry-description">
              <div className='industry-description-heading'>Description:</div><div className='industry-description-content'> Our innovative solutions help financial institutions manage risk, improve customer experience, and stay compliant with regulatory requirements.</div>
              <div className='industry-description-heading'>Case Study:</div><div className='industry-description-content'> Developed a secure online banking platform for a leading bank, increasing customer satisfaction and online engagement by 40%.</div>
              <div className="case-study-link"><a href="/case-study/finance">Read Full Case Study &gt;&gt;</a></div>
            </div>
          </div>
        </div>
        <div className="industry-left">
          <img src={IndustryImage2} alt="Industry 2" />
        </div>
      </div>
      <div className="industry-item">
        <div className="industry-left">
          <img src={IndustryImage3} alt="Industry 3" />
        </div>
        <div className="industry-right">
          <div className="industry-number">03.</div>
          <div className="industry-content">
            <div className="industry-heading">Retail</div>
            <div className="industry-description">
              <div className='industry-description-heading'>Description:</div><div className='industry-description-content'> We offer solutions that enhance customer experience, optimize supply chains, and drive sales for retail businesses.</div>
              <div className='industry-description-heading'>Case Study:</div><div className='industry-description-content'> Implemented an omnichannel retail strategy for a major retailer, leading to a 25% increase in overall sales and improved customer satisfaction.</div>
              <div className="case-study-link"><a href="/case-study/retail">Read Full Case Study &gt;&gt;</a></div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

      <div className="resources-center">
        <div className="resources-upper">
          <div className="line-left"></div>
          <div className="trusted-partners">Our Trusted Partners</div>
          <div className="line-right"></div>
        </div>
        <div className="resources-lower">
          <div className="company-item">
            <div className="company-logo">
              <img src={CompanyLogo1} alt="Company 1" />
            </div>
            <div className="company-name">Company 1</div>
          </div>
          <div className="company-item">
            <div className="company-logo">
              <img src={CompanyLogo2} alt="Company 2" />
            </div>
            <div className="company-name">Company 2</div>
          </div>
          <div className="company-item">
            <div className="company-logo">
              <img src={CompanyLogo3} alt="Company 3" />
            </div>
            <div className="company-name">Company 3</div>
          </div>
          <div className="company-item">
            <div className="company-logo">
              <img src={CompanyLogo4} alt="Company 4" />
            </div>
            <div className="company-name">Company 4</div>
          </div>
          <div className="company-item">
            <div className="company-logo">
              <img src={CompanyLogo5} alt="Company 5" />
            </div>
            <div className="company-name">Company 5</div>
          </div>
          <div className="company-item">
            <div className="company-logo">
              <img src={CompanyLogo6} alt="Company 6" />
            </div>
            <div className="company-name">Company 6</div>
          </div>
        </div>
      </div>
    <div className="careers-container">
      <div className="upper-section">
        <div className="line-image-home">
          <img src={LineImage} alt="line" />
          <div className="testimonials-title">Testimonials</div>
        </div>
       

        <div className="testimonials-heading">What Our Clients Say</div>
      </div>
      <div className="lower-section">
        <img src={LeftArrow} alt="left arrow" className="arrow left-arrow" onClick={handleLeftClick} />
        <div className="cards-container">
          {clients.slice(currentIndex, currentIndex + 3).map((client, index) => (
            <div
              className={`card ${index === currentIndex ? 'active' : ''}`}
              key={index}
              // style={{ opacity: index === currentIndex ? 1 : 0, transition: 'opacity 0.5s' }}
            >
              <div className="card-image">
                <img src={client.img} alt={`Client ${index + 1}`} />
              </div>
              <div className="card-content">
                <div className="card-icon">
                  <img src={Comma} alt="Star" />
                  <img src={Comma} alt="Star" />
                </div>
                <div className="card-name">{client.name}</div>
                <div className="card-designation">{client.designation}</div>
                <div className="card-stars">
                  <img src={StarIcon} alt="Star" />
                  <img src={StarIcon} alt="Star" />
                  <img src={StarIcon} alt="Star" />
                  <img src={StarIcon} alt="Star" />
                  <img src={StarIcon} alt="Star" />
                </div>
                <div className="card-text">{client.text}</div>
              </div>
            </div>
          ))}
        </div>
        <img src={RightArrow} alt="right arrow" className="arrow right-arrow" onClick={handleRightClick} />
      </div>
    </div>
    <div className="main-container">
      <div className="background-overlay">
        <div className="content">
          <div className="heading">
            Ready to transform your ideas into reality?
          </div>
          <div className="subheading">
            Reach out to discover how we can revolutionize your digital
            landscape.
          </div>
          <div className="button-container-contactus">
            <div className="hover-bg-contactus"></div>
            <button
              className="get-in-touch-button"
              onClick={handleOpenContactUs}
            >
              Get In Touch
            </button>
          </div>
        </div>
      </div>
      {isContactUsVisible && (
        <div className="overlay">
          <div className="contact-us-container">
            <div className="upper-section-form">
            <button className="close-button" onClick={handleCloseContactUs}>
              X
            </button>
            <h1>Contact Us</h1>
            <p>
              We'd love to hear from you! Reach out with any questions or to
              discuss how we can help transform your digital presence.
            </p>
            </div>
            <form className="contact-us-form" onSubmit={handleSubmit}>
              <div className="Contactus-form-fullname">
                <div className="name-left">
                  <label>First name</label>
                  <input type="text" name="name" required />
                </div>
                <div className="name-right">
                  <label>Last name</label>
                  <input type="text" name="name" required />
                </div>
              </div>
              <div className="Contactus-form-email-phonenumber">
                <div className="email">
                  <label>Email ID</label>
                  <input type="email" name="email" required />
                </div>
                <div className="number">
                  <label for="phoneNumber">Phone number</label>
                  <input
                    type="number"
                    name="number"
                    id="phoneNumber"
                    required
                  />
                </div>
              </div>
              <div className="message">
                <label>Message:</label>
                <textarea name="message" required></textarea>
              </div>
              <div class="checkbox-container-contactus">
                <input type="checkbox" id="receive-info" name="receive-info" />
                <div classname="receive-info">
                 I'd like to receive more information about the company.  I understand and agree to the <span className="receive-info-span">Privacy Policy</span>. 
                </div>
              </div>
              <div className="submit-cancel-btn-contactus-form">
                <button className="cancel-btn-contactus-form" type="cancel">
                  Cancel
                </button>
                <button className="submit-btn-contactus-form" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
       </div>
        
  );
}

export default Home;
