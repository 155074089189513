// src/components/ContactUs.js
import React from "react";
import "../../Pages/ContactUs/ContactUs.css";

function ContactUs() {
  // const [isContactUsVisible, setContactUsVisible] = useState(false);

  // const handleCloseContactUs = () => {
  //   setContactUsVisible(false);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <h1>Contact Us</h1>
      <div className="syntax-contactus">
      <div className="box-1"></div>
        <div className="overlay-navbar-contactUs">
          <div className="contactus-container">
            <div className="upper-section-form">
              {/* <button className="close-button" onClick={handleCloseContactUs}>
              X
            </button> */}

              <p>
                We'd love to hear from you! Reach out with any questions or to
                discuss how we can help transform your digital presence.
              </p>
            </div>
            <form className="contact-us-form" onSubmit={handleSubmit}>
              <div className="Contactus-form-fullname">
                <div className="name-left">
                  <label>First name</label>
                  <input type="text" name="name" required />
                </div>
                <div className="name-right">
                  <label>Last name</label>
                  <input type="text" name="name" required />
                </div>
              </div>
              <div className="Contactus-form-email-phonenumber">
                <div className="email">
                  <label>Email ID</label>
                  <input type="email" name="email" required />
                </div>
                <div className="number">
                  <label for="phoneNumber">Phone number</label>
                  <input
                    type="number"
                    name="number"
                    id="phoneNumber"
                    required
                  />
                </div>
              </div>
              <div className="message">
                <label>Message:</label>
                <textarea name="message" required></textarea>
              </div>
              <div class="checkbox-container-contactus">
                <input type="checkbox" id="receive-info" name="receive-info" />
                <div classname="receive-info">
                  I'd like to receive more information about the company. I
                  understand and agree to the{" "}
                  <span className="receive-info-span">Privacy Policy</span>.
                </div>
              </div>
              <div className="submit-cancel-btn-contactus-form">
                {/* <button className="cancel-btn-contactus-form" type="cancel">
                  Cancel
                </button> */}
                <button className="submit-btn-contactus-form" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="box-2"></div>
      </div>
    </div>
  );
}

export default ContactUs;
