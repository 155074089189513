import React from "react";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/career-search-icon.svg";
import CareerLineImage from "../../assets/industriesline.png";
import ArrowOpportunity from "../../assets/right-arrow-testimonials.svg";
import Suitcase from "../../assets/suitcase-opportunities.svg";
import { PiCaretDoubleRight } from "react-icons/pi";
import ClockPost from "../../assets/charm_clock.svg";
import CareerBg from "../../assets/services_center_image1.png"
import "./career.css";

function Careers() {

  const navigate = useNavigate();

  const handleReadMore = (id) => {
    console.log("Amarh here");
    navigate(`/job/${id}/`);
    // navigate(`/job/${id}`);
  };


  return (
    <div className="career-section">
      <div className="career-section-1" style={{backgroundImage: `url(${CareerBg})`}}>
        <div className="career-title">Find Your Next Career Opportunity</div>
        <div className="career-subtitle">
          Start your journey with Genoday Technologies today.
        </div>
        <div className="career-search">
          <img
            src={SearchIcon}
            alt="SearchImage"
            className="career-search-icon"
          />
          <input
            type="text"
            className="search-input"
            placeholder="Job Title or keywords"
          />
          <button className="find-jobs-button">Find Jobs</button>
        </div>
      </div>
      <div className="career-line-image">
        <img src={CareerLineImage} alt="line" />
      </div>
      <div className="career-content-div">
        <div className="career-title-second-heading">current openings</div>
        <div className="career-heading-sub">
        Explore the opportunities to join our team.
        </div>
      </div>
      <div className="main-list-opportunities">
        <div className="sub-list-opportunities-upper-conatiner">
        <div className="sub-list-opportunities">
          <div className="position-opportunities">Software Engineer</div>
          <div className="job-type-postedtime-main">
            <div className="job-type-opportunities">
              <div className="icon-job-type-opportunities">
              <img src={Suitcase} alt="Suitcase" className="suitcase"/>
              </div>
              <div className="text-job-type">full time</div>
            </div>
            <div className="icon-postedtime-opportunities">
              <div className="icon-postedtime">
              <img src={ClockPost} alt="ClockPost" className="ClockPost"/>
              </div>
              <div className="postedtime">Posted 2 years ago</div>
            </div>
          </div>
          <div className="description-opportunities">Develop and maintain software applications, collaborate with cross-functional teams, and ensure high-quality code.</div>
          <div className="button-arrow-opportunities">
          <button
                className="read-more-opportunities"
                onClick={() => handleReadMore(1)}
              >
                Read More <PiCaretDoubleRight className="caret-icon-read-more" />
              </button>
            {/* <div className="main-arrow-opportunities">
            <img src={ArrowOpportunity} alt="ArrowOpportunity" className="arrow-opportunities-left"/>
            <img src={ArrowOpportunity} alt="ArrowOpportunity" className="arrow-opportunities-right"/>
            </div> */}
          </div>
        </div>
        <div className="sub-list-opportunities">
          <div className="position-opportunities">Software Engineer</div>
          <div className="job-type-postedtime-main">
            <div className="job-type-opportunities">
              <div className="icon-job-type-opportunities">
              <img src={Suitcase} alt="Suitcase" className="suitcase"/>
              </div>
              <div className="text-job-type">full time</div>
            </div>
            <div className="icon-postedtime-opportunities">
              <div className="icon-postedtime">
              <img src={ClockPost} alt="ClockPost" className="ClockPost"/>
              </div>
              <div className="postedtime">Posted 2 years ago</div>
            </div>
          </div>
          <div className="description-opportunities">Develop and maintain software applications, collaborate with cross-functional teams, and ensure high-quality code.</div>
          <div className="button-arrow-opportunities">
             <button
                className="read-more-opportunities"
                onClick={() => handleReadMore(1)}
              >
                Read More <PiCaretDoubleRight className="caret-icon-read-more" />
              </button>
          </div>
        </div>
        <div className="sub-list-opportunities">
          <div className="position-opportunities">Software Engineer</div>
          <div className="job-type-postedtime-main">
            <div className="job-type-opportunities">
              <div className="icon-job-type-opportunities">
              <img src={Suitcase} alt="Suitcase" className="suitcase"/>
              </div>
              <div className="text-job-type">full time</div>
            </div>
            <div className="icon-postedtime-opportunities">
              <div className="icon-postedtime">
              <img src={ClockPost} alt="ClockPost" className="ClockPost"/>
              </div>
              <div className="postedtime">Posted 2 years ago</div>
            </div>
          </div>
          <div className="description-opportunities">Develop and maintain software applications, collaborate with cross-functional teams, and ensure high-quality code.</div>
          <div className="button-arrow-opportunities">
             <button
                className="read-more-opportunities"
                onClick={() => handleReadMore(1)}
              >
                Read More <PiCaretDoubleRight className="caret-icon-read-more" />
              </button>
          </div>
        </div>
        </div>
        <div className="sub-list-opportunities-lower-conatiner">
        <div className="sub-list-opportunities">
          <div className="position-opportunities">Software Engineer</div>
          <div className="job-type-postedtime-main">
            <div className="job-type-opportunities">
              <div className="icon-job-type-opportunities">
              <img src={Suitcase} alt="Suitcase" className="suitcase"/>
              </div>
              <div className="text-job-type">full time</div>
            </div>
            <div className="icon-postedtime-opportunities">
              <div className="icon-postedtime">
              <img src={ClockPost} alt="ClockPost" className="ClockPost"/>
              </div>
              <div className="postedtime">Posted 2 years ago</div>
            </div>
          </div>
          <div className="description-opportunities">Develop and maintain software applications, collaborate with cross-functional teams, and ensure high-quality code.</div>
          <div className="button-arrow-opportunities">
             <button
                className="read-more-opportunities"
                onClick={() => handleReadMore(1)}
              >
                Read More <PiCaretDoubleRight className="caret-icon-read-more" />
              </button>
          </div>
        </div>
        <div className="sub-list-opportunities">
          <div className="position-opportunities">Software Engineer</div>
          <div className="job-type-postedtime-main">
            <div className="job-type-opportunities">
              <div className="icon-job-type-opportunities">
              <img src={Suitcase} alt="Suitcase" className="suitcase"/>
              </div>
              <div className="text-job-type">full time</div>
            </div>
            <div className="icon-postedtime-opportunities">
              <div className="icon-postedtime">
              <img src={ClockPost} alt="ClockPost" className="ClockPost"/>
              </div>
              <div className="postedtime">Posted 2 years ago</div>
            </div>
          </div>
          <div className="description-opportunities">Develop and maintain software applications, collaborate with cross-functional teams, and ensure high-quality code.</div>
          <div className="button-arrow-opportunities">
             <button
                className="read-more-opportunities"
                onClick={() => handleReadMore(1)}
              >
                Read More <PiCaretDoubleRight className="caret-icon-read-more" />
              </button>
          </div>
        </div>
        <div className="sub-list-opportunities">
          <div className="position-opportunities">Software Engineer</div>
          <div className="job-type-postedtime-main">
            <div className="job-type-opportunities">
              <div className="icon-job-type-opportunities">
              <img src={Suitcase} alt="Suitcase" className="suitcase"/>
              </div>
              <div className="text-job-type">full time</div>
            </div>
            <div className="icon-postedtime-opportunities">
              <div className="icon-postedtime">
              <img src={ClockPost} alt="ClockPost" className="ClockPost"/>
              </div>
              <div className="postedtime">Posted 2 years ago</div>
            </div>
          </div>
          <div className="description-opportunities">Develop and maintain software applications, collaborate with cross-functional teams, and ensure high-quality code.</div>
          <div className="button-arrow-opportunities">
             <button
                className="read-more-opportunities"
                onClick={() => handleReadMore(1)}
              >
                Read More <PiCaretDoubleRight className="caret-icon-read-more" />
              </button>
          </div>
        </div>
        </div>
        
      </div>
    </div>
  );
}

export default Careers;
