import React from "react";
import CareerLineImage from "../../assets/industriesline.svg";
import BlogOne from "../../assets/Industries/first-card-industries.png";
import BlogTwo from "../../assets/Industries/second-card-industries.png";
import BlogThree from "../../assets/Industries/third-card-industries.png";
import FirstProfilePic from "../../assets/Industries/profile-pic-first-industries.png";
import SecondProfilePic from "../../assets/Industries/profile-pic-second-industries.png";
import ThirdProfilePic from "../../assets/Industries/profile-pic-third-industries.png";
import LineImage from "../../assets/aboutus-line.svg";
import "./Resources.css";

function resources() {
  return (
    <div className="resources-container-page">
      <div className="resources">Resources</div>
      <div className="resources-line-image">
        <img src={CareerLineImage} alt="line" />
      </div>
      <div className="resources-content-div">
        <div className="resources-title-second-heading">Featured Articles</div>
        <div className="resources-heading-sub">Latest Insights & Trends</div>
      </div>
      <div className="main-resources-featured-card">
        <div className="resources-featured-card-up">
          <div className="resources-featured-card">
            <div className="upper-sec-resources-featured-card">
              <img src={BlogOne} alt="firstimage" />
            </div>
            <div className="lower-sec-resources-featured-card">
              <div className="first-line-lower-sec-resources-featured-card">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card">
                  <img src={FirstProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card">
                  <span className="name-first-line-lower-sec-resources-featured-card">
                    Jane Doe
                  </span>
                  <span className="dot-first-line-lower-sec-resources-featured-card">
                    .
                  </span>
                  <span className="readtime-first-line-lower-sec-resources-featured-card">
                    5 min read
                  </span>
                </div>
              </div>
              <div className="heading-first-line-lower-sec-resources-featured-card">
                The future Of AI In Healthcare
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card">
                Discover how artificial intelligence is revolutionizing
                healthcare delivery and patient outcomes.
              </div>
              <div className="date-first-line-lower-sec-resources-featured-card">
                july 10, 2024
              </div>
            </div>
          </div>
          <div className="resources-featured-card">
            <div className="upper-sec-resources-featured-card">
              <img src={BlogTwo} alt="secondimage" />
            </div>
            <div className="lower-sec-resources-featured-card">
              <div className="first-line-lower-sec-resources-featured-card">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card">
                  <img src={SecondProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card">
                  <span className="name-first-line-lower-sec-resources-featured-card">
                    John Smith
                  </span>
                  <span className="dot-first-line-lower-sec-resources-featured-card">
                    .
                  </span>
                  <span className="readtime-first-line-lower-sec-resources-featured-card">
                    5 min read
                  </span>
                </div>
              </div>
              <div className="heading-first-line-lower-sec-resources-featured-card">
                Best Practices for Cloud Security
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card">
                Learn the top strategies to secure your cloud infrastructure.
              </div>
              <div className="date-first-line-lower-sec-resources-featured-card">
                June 25, 2024
              </div>
            </div>
          </div>
          <div className="resources-featured-card">
            <div className="upper-sec-resources-featured-card">
              <img src={BlogThree} alt="thirdimage" />
            </div>
            <div className="lower-sec-resources-featured-card">
              <div className="first-line-lower-sec-resources-featured-card">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card">
                  <img src={ThirdProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card">
                  <span className="name-first-line-lower-sec-resources-featured-card">
                    Emily Johnson
                  </span>
                  <span className="dot-first-line-lower-sec-resources-featured-card">
                    .
                  </span>
                  <span className="readtime-first-line-lower-sec-resources-featured-card">
                    10 min read
                  </span>
                </div>
              </div>
              <div className="heading-first-line-lower-sec-resources-featured-card">
                Optimizing Mobile App Performance
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card">
                Explore techniques to enhance the speed and efficiency of your
                mobile applications.
              </div>
              <div className="date-first-line-lower-sec-resources-featured-card">
                July 5, 2024
              </div>
            </div>
          </div>
        </div>
        <div className="resources-featured-card-down">
          <div className="resources-featured-card">
            <div className="upper-sec-resources-featured-card">
              <img src={BlogThree} alt="thirdimage" />
            </div>
            <div className="lower-sec-resources-featured-card">
              <div className="first-line-lower-sec-resources-featured-card">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card">
                  <img src={ThirdProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card">
                  <span className="name-first-line-lower-sec-resources-featured-card">
                    Emily Johnson
                  </span>
                  <span className="dot-first-line-lower-sec-resources-featured-card">
                    .
                  </span>
                  <span className="readtime-first-line-lower-sec-resources-featured-card">
                    10 min read
                  </span>
                </div>
              </div>
              <div className="heading-first-line-lower-sec-resources-featured-card">
                Optimizing Mobile App Performance
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card">
                Explore techniques to enhance the speed and efficiency of your
                mobile applications.
              </div>
              <div className="date-first-line-lower-sec-resources-featured-card">
                July 5, 2024
              </div>
            </div>
          </div>
          <div className="resources-featured-card">
            <div className="upper-sec-resources-featured-card">
              <img src={BlogOne} alt="firstimage" />
            </div>
            <div className="lower-sec-resources-featured-card">
              <div className="first-line-lower-sec-resources-featured-card">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card">
                  <img src={FirstProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card">
                  <span className="name-first-line-lower-sec-resources-featured-card">
                    Jane Doe
                  </span>
                  <span className="dot-first-line-lower-sec-resources-featured-card">
                    .
                  </span>
                  <span className="readtime-first-line-lower-sec-resources-featured-card">
                    5 min read
                  </span>
                </div>
              </div>
              <div className="heading-first-line-lower-sec-resources-featured-card">
                The future Of AI In Healthcare
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card">
                Discover how artificial intelligence is revolutionizing
                healthcare delivery and patient outcomes.
              </div>
              <div className="date-first-line-lower-sec-resources-featured-card">
                july 10, 2024
              </div>
            </div>
          </div>
          <div className="resources-featured-card">
            <div className="upper-sec-resources-featured-card">
              <img src={BlogTwo} alt="secondimage" />
            </div>
            <div className="lower-sec-resources-featured-card">
              <div className="first-line-lower-sec-resources-featured-card">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card">
                  <img src={SecondProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card">
                  <span className="name-first-line-lower-sec-resources-featured-card">
                    John Smith
                  </span>
                  <span className="dot-first-line-lower-sec-resources-featured-card">
                    .
                  </span>
                  <span className="readtime-first-line-lower-sec-resources-featured-card">
                    5 min read
                  </span>
                </div>
              </div>
              <div className="heading-first-line-lower-sec-resources-featured-card">
                Best Practices for Cloud Security
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card">
                Learn the top strategies to secure your cloud infrastructure.
              </div>
              <div className="date-first-line-lower-sec-resources-featured-card">
                June 25, 2024
              </div>
            </div>
          </div>
        </div>
        <button className="view-btn-resources">View All</button>
      </div>
      <div className="last-sec-resources">
        <div className="line-image">
          <img src={LineImage} alt="line" />
        </div>
        <div className="resources-title-service">Blog Posts</div>
        <div className="resources-heading-service">
          Stay Informed With Our Latest Blog Entries
        </div>
        <button className="last-sec-view-btn-resources">View All</button>
        <div className="main-resources-featured-card-scroll">
          <div className="resources-featured-card-scroll">
            <div className="upper-sec-resources-featured-card-scroll">
              <img src={BlogOne} alt="firstimage" />
            </div>
            <div className="lower-sec-resources-featured-card-scroll">
              <div className="heading-first-line-lower-sec-resources-featured-card-scroll">
                The future Of AI In Healthcare
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card-scroll">
                Discover how artificial intelligence is revolutionizing
                healthcare delivery and patient outcomes.
              </div>
              <div className="first-line-lower-sec-resources-featured-card-scroll">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card-scroll">
                  <img src={FirstProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card-scroll">
                  <span className="name-first-line-lower-sec-resources-featured-card-scroll">
                    Jane Doe
                  </span>
                  <span className="date-first-line-lower-sec-resources-featured-card-scroll">
                    july 10, 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="resources-featured-card-scroll">
            <div className="upper-sec-resources-featured-card-scroll">
              <img src={BlogOne} alt="firstimage" />
            </div>
            <div className="lower-sec-resources-featured-card-scroll">
              <div className="heading-first-line-lower-sec-resources-featured-card-scroll">
                The future Of AI In Healthcare
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card-scroll">
                Discover how artificial intelligence is revolutionizing
                healthcare delivery and patient outcomes.
              </div>
              <div className="first-line-lower-sec-resources-featured-card-scroll">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card-scroll">
                  <img src={FirstProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card-scroll">
                  <span className="name-first-line-lower-sec-resources-featured-card-scroll">
                    Jane Doe
                  </span>
                  <span className="date-first-line-lower-sec-resources-featured-card-scroll">
                    july 10, 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="resources-featured-card-scroll">
            <div className="upper-sec-resources-featured-card-scroll">
              <img src={BlogOne} alt="firstimage" />
            </div>
            <div className="lower-sec-resources-featured-card-scroll">
              <div className="heading-first-line-lower-sec-resources-featured-card-scroll">
                The future Of AI In Healthcare
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card-scroll">
                Discover how artificial intelligence is revolutionizing
                healthcare delivery and patient outcomes.
              </div>
              <div className="first-line-lower-sec-resources-featured-card-scroll">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card-scroll">
                  <img src={FirstProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card-scroll">
                  <span className="name-first-line-lower-sec-resources-featured-card-scroll">
                    Jane Doe
                  </span>
                  <span className="date-first-line-lower-sec-resources-featured-card-scroll">
                    july 10, 2024
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="resources-featured-card-scroll">
            <div className="upper-sec-resources-featured-card-scroll">
              <img src={BlogOne} alt="firstimage" />
            </div>
            <div className="lower-sec-resources-featured-card-scroll">
              <div className="heading-first-line-lower-sec-resources-featured-card-scroll">
                The future Of AI In Healthcare
              </div>
              <div className="sub-heading-first-line-lower-sec-resources-featured-card-scroll">
                Discover how artificial intelligence is revolutionizing
                healthcare delivery and patient outcomes.
              </div>
              <div className="first-line-lower-sec-resources-featured-card-scroll">
                <div className="profile-pic-first-line-lower-sec-resources-featured-card-scroll">
                  <img src={FirstProfilePic} alt="profilepicture" />
                </div>
                <div className="userinfo-first-line-lower-sec-resources-featured-card-scroll">
                  <span className="name-first-line-lower-sec-resources-featured-card-scroll">
                    Jane Doe
                  </span>
                  <span className="date-first-line-lower-sec-resources-featured-card-scroll">
                    july 10, 2024
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default resources;
